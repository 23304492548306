body{
  overflow-x: hidden;
  max-width: 100%;
  text-rendering:optimizeLegibility !important;
  background-color: black !important;
}

.dark{
  background-color: black !important;
}

.light{
  background-color:white !important;
  /* background: -webkit-linear-gradient(#ffdaf3, #d3f4ff);
  background: -moz-linear-gradient(#ffdaf3, #d3f4ff);
  background: linear-gradient(#ffdaf3, #d3f4ff); */
}

#root{
  position: relative;
}

html{
  overflow-x: clip;
}


.navbar-light .router-link{
  color: black !important;
}
.navbar-dark .router-link{
  color: white !important;
}
.router-link-dark:hover{
  color: lightgrey !important;
}
.router-link-light:hover{
  color: rgb(84, 84, 84) !important;
}
@media only screen and (max-width: 600px) {
  .navbar-light .router-link{
    color: white !important;
  }
  
  .navbar-dark .router-link{
    color: white !important;
  }
  
  #darkModeButton{
    color: red !important;
  }
  .router-link-dark:hover{
    color: lightgrey !important;
  }

}

.router-link{
  text-decoration: none !important;
  color: grey;
  text-align: center;
  z-index: 1;
}


.our-nav{
  overflow: hidden;
  z-index: 1;
  /* position: absolute !important; */
  top: 0;
  width: 100%;
  background-color: transparent;
}

#footer{
  color: white;
  background-color: #171717;
  text-align: center;
  margin-top: 10%;
  left: 0 !important;
  bottom: 0 !important;
  width: 100%;
}

#darkModeButton{
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
}

/* #darkModeButton:hover{
  background-color: rgb(235, 237, 240);
  border-radius: 20%;
} */