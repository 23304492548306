#services-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content:space-between;
    align-items:center;
    align-self: center;
    gap: 10px;
  }

  #services-container .card{
    flex-basis: 30%;
  }
  
  /* .animate__animated.animate__slideInUp {
    --animate-delay: 10s;
  } */
  
  .section-header{
    color: white;
    font-weight: bold;
  }
  
  .App-light h1.section-header{
    color:black;
  }
  
  @media only screen and (max-width: 600px) {
    #services-container{
      flex-direction: column !important;
      
    }
    #services{
      margin-top: -30% !important;
    }
    .section-header{
      text-align: center !important;
      margin-bottom: 10%;
    }
    .blob{
      visibility: hidden;
    }
    .our-nav{
      background: -webkit-linear-gradient(99deg, #363235, #625e5e) !important;
      color:white;
      opacity: 1;
    }
    #testimonials h1{
      font-size: xx-large !important;
    }
  }

  #blob-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1199.5px){
    #blob-container{
      justify-content: center;
    }
    #services-container .card{
      flex-basis: 40%;
    }
    #testimonials h1{
      font-size: x-large;
    }
  }
  

  #services{
    /* border: 1px solid green; */
    margin-top: 10%;
    /* background-image: url('../../../public/imgs/blob2.webp');
    background-repeat: no-repeat;
    overflow:visible;
    background-position-y:center;
    background-size:85%; */
  }
  
  #testimonials{
    margin-top: 5%;
  }

  .apple_button{
    background: -webkit-linear-gradient(180deg, #5c4e60, #0D0D0D);
    border: 2px solid black;
  }

  .apple_button:hover{
    background-image:none;
    background-color: white;
    color: black;
  }

  .apple_button:hover #contact{
    color: black;
  }

  #contact{
    color: white;
    text-decoration: none;
  }

  .color-title-header{
    -webkit-text-fill-color: transparent;
background-clip: text;
background-color: rgb(146, 84, 222);
background-image: linear-gradient(45deg, rgb(146, 84, 222), rgb(89, 126, 247));
  }


.blob:nth-of-type(1){
  animation: float 6s ease-in-out infinite;
}

.blob:nth-of-type(2){
  animation: float 7.5s ease-in-out infinite;
}

.blob{
  width:500px;
  height:500px;
}

video {
  object-fit: cover;
  width: 80vw;
  height: 50vh;
  top: 0;
  left: 0;
  opacity: 0.4;
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
}
