.about-card{
    width: 100%;
    border-radius: 25px;
    padding: 5%;
    font-size: large;
}


.right-card{
    background: -webkit-linear-gradient(-45deg, rgb(146, 84, 222), rgb(89, 126, 247));
}

.left-card{
    background: -webkit-linear-gradient(0deg, rgb(180, 84, 222), rgb(89, 126, 247));
}
