.main-work-card{
    background-clip:content-box;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height:500px;
    border-radius: 25px;
}

.work-header{
    color: white;
    font-size:350%;
    font-weight: 700;
    text-align: left;
}

.App-light h1.work-header{
    color:black;
}

.work-subhead{
    color: white;
    font-size: xx-large;
    font-weight: 700;
}

.desc-text{
    color: #BEBEBE;
    font-size: x-large;
    font-weight: 700;
}

.work-card-container{
    /* border: 1px solid green; */
    width: 100%;
    margin-top: 5%;
}

.work-card-text{
    display: flex;
    /* border: 1px solid red; */
    /* Bottom will need to change same thing with header positioning */
    flex-direction: column;
    width: 40%;
}

.work-right{
    padding-top: 20%;
    text-align: right;
}

.work-left{
    padding-top: 20%;
    padding-left: 55%;
    width: 100% !important;
    text-align: left;
}

.inner-work-text{
    /* border: 1px solid purple; */
    width: 50%;
}

.r-align{
    text-align: right;
}
.l-align{
    text-align: left;
}