body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: black !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.center{
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  flex-direction: column;
  margin-top: 10%;
}


@media only screen and (max-width: 500px) {
  .landing-title{
    font-size: 300% !important;
  }
  .background-blob{
    top: -16rem !important;
  }
}

.landing-title{
  font-size:3.75rem;
  /* background: -webkit-linear-gradient(261deg, #5c4e60, #0D0D0D);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color:transparent;  */
  font-weight: bold;
}

#bottom-title{
  padding-bottom: 5px;
}

.sub-title{
  color: black;
  font-size: 150%;
  font-weight: 500;
}

#landing Button{
  margin-top: 3%;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-40px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes bigfloat {
  0% {
    transform: translatey(-10px);
  }
  50% {
    transform: translatey(10px);
  }
  100% {
    transform: translatey(-10px);
  }
}
.background-blob{
  animation: bigfloat 8.37s ease-in-out infinite;
  position: absolute;
  top: -9rem;
  z-index: -1;
  width: 1024px;
  left: calc(50% - 530px);
}
/* body{
  background-image:
  radial-gradient(circle at 50% 20%, 
  hsl(280deg 10% 71%) 0%,
  hsl(231deg 10% 16%) 15%,
  hsl(233deg 100% 2%) 24%,
  hsl(339deg 0% 0%) 36%,
  hsl(339deg 0% 0%) 56%,
  hsl(0deg 0% 0%) 100%
);

  background-repeat: no-repeat;
  background-position:center;
  background-position-y:-15rem;
} */