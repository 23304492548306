.card{
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: space-between;
    aspect-ratio: 1.1;
    padding: 2%;
    border: 1px solid black;
    flex: 1 0 0px;
    margin: 0;
    // Issue with text being in top left, but not the biggest deal...
    @supports (not (aspect-ratio: 1.1)) and (-webkit-appearance: none) {
      height: 0;
      padding-bottom: 25%;
      // @media only screen and (max-width: 600px) {
      //   height: auto;
      //   padding-bottom: 100%;
      //   padding: 5%;
      // }
    }
}

.service-name{
    font-weight:200;
}

.service-desc{
    color: white;
    /* margin-top: 50%; */
    font-weight: bold;
    /* text-shadow:0 0 0 black, 0 0 0.1px transparent; */
}

.card-link{
    /* color: #BEBEBE; */
    font-weight:500;
    text-decoration: underline;
    margin-bottom: 5%;
    transition: linear 0.1s;
}

.card-link:hover{
    color:lightgrey !important;
}

@media only screen and (max-width: 600px) {
    .card{
      width: 80%;
      margin-bottom: 5%
    }
    
  }