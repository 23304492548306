@media only screen and (max-width: 500px) {
    .about-sec{
      flex-direction: column !important;
      font-size: larger;
    }
    .rev{
        flex-direction: column-reverse !important;
    }
    #video-ad-container{
        width: 100% !important;
    }
  }

.about-header{
    color: white;
    font-size:350%;
    font-weight: 700;
}

.App-light h1.about-header{
    color:black;
}

.App-dark .center p{
    color: white;
}

.App-dark .center h1{
    color:white !important;
}

.about-sec{
    margin-top: 10%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
}


#video-ad-container{
    margin-top: 15%;
    border: 15px solid white;
    /* height: 20vh;
    width: 100%; */
    background: -webkit-linear-gradient(90deg, #FFFFFF 0%, #DFFDFF 100%);
    border-radius: 25px;    
    /* width: 100%;
    height: 100%; */
}

@media only screen and (max-width: 600px) {
    #our-ad{
        width: 100% !important;
    }
  }

/* #our-ad{
    width: 100% !important;
    height: 100% !important;
    object-fit: fill;
} */

/* video{
    min-width: 100%;
    min-height: 100%;
    object-fit: fill;
} */