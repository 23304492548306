.main-intro-card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    align-self: center;
    flex-direction: column;
    margin-top: 10%;
}

.main-intro-card-container h1{
    color: white;
    font-weight: bold;
}

.diff-top-margin{
    margin-top: -20% !important;
    margin-bottom: 10%;
}

.pink-card{
    background-image: linear-gradient(0deg, rgb(146, 84, 222), rgb(89, 126, 247));
    width: fit-content;
    color: white;
    border-radius: 25px;
    padding: 2%;
    font-size: large;
}

@media only screen and (max-width: 600px) {
    .main-intro-card-container{
        margin-top: 20%;
        margin-bottom: 50%;
    }
    .diff-top-margin{
        margin-top: -15% !important;
        margin-bottom: 10%;
    }

}