.service-card-container{
    /* border: 1px solid green; */
    position: relative;
    width: 100%;
    height: 500px;
    margin-top: 5%;
    transform-style: preserve-3d;
    perspective: 5000px; /* Remove this if you don't want the 3D effect */
}

.serv-icon{
    width: 20%;
}

.service-card{
    overflow: hidden;
    position: absolute;
    background: rgb(23,23,23);
    width: 100%;
    height:500px;
    border-radius: 25px;
    display: flex;
    transition: transform 0.5s;
}

@media not all and (min-resolution:.001dpcm){
    .service-card-front, .service-card-back {
        transform: none !important;
    }
}

.service-card-front{
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.service-card-back{
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(180deg);
    background: rgb(23,23,23);
}


.service-card-container:hover .service-card-front{
    transform: rotateY(180deg);
}
.service-card-container:hover .service-card-back{
    transform: rotateY(360deg);
}

.service-card-text{
    display: flex;
    /* border: 1px solid red; */
    /* Bottom will need to change same thing with header positioning */
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding-top: 3%;
    padding-left: 3%;
    text-align: left;
}

.service-tag{
    color: #BEBEBE;
    font-size: large;
    font-weight: 500;
}
.service-title{
    color: white;
    font-size: xxx-large;
    font-weight: 700;
    text-align: left;
}
.service-desc{
    color: white;
    font-weight: 700;
}

.service-desc-serv-card{
    color: #BEBEBE;
    font-size: x-large;
    font-weight: 700;
}
/* 
.background-1{
    background: -moz-radial-gradient(circle at 90% 10%, rgba(0, 255, 133, 0.89) 0%, rgba(0, 255, 133, 0.11) 29%, rgba(0, 255, 133, 0.01) 37%),-moz-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: -webkit-radial-gradient(circle at 90% 10%, rgba(0, 255, 133, 0.89) 0%, rgba(0, 255, 133, 0.11) 29%, rgba(0, 255, 133, 0.01) 37%),-webkit-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: radial-gradient(circle at 90% 10%, rgba(0, 255, 133, 0.89) 0%, rgba(0, 255, 133, 0.11) 29%, rgba(0, 255, 133, 0.01) 37%),linear-gradient(90deg, #171717 0%, #333333 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171717",endColorstr="#333333",GradientType=1);
}

.background-1 .service-modal-button:hover {
    border: 1px solid rgba(0, 255, 133, 0.89);
    background-color: rgba(0, 255, 133, 0.11);
}
.background-2{
    background: -moz-radial-gradient(circle at 90% 10%, rgba(0, 194, 255, 0.89) 0%, rgba(0, 194, 255, 0.11) 29%, rgba(0, 194, 255, 0.01) 37%),-moz-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: -webkit-radial-gradient(circle at 90% 10%, rgba(0, 194, 255, 0.89) 0%, rgba(0, 194, 255, 0.11) 29%, rgba(0, 194, 255, 0.01) 37%),-webkit-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: radial-gradient(circle at 90% 10%, rgba(0, 194, 255, 0.89) 0%, rgba(0, 194, 255, 0.11) 29%, rgba(0, 194, 255, 0.01) 37%),linear-gradient(90deg, #171717 0%, #333333 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171717",endColorstr="#333333",GradientType=1);
}

.background-2 .service-modal-button:hover {
    border: 1px solid rgba(0, 194, 255, 0.89);
    background-color: rgba(0, 194, 255, 0.11);
}
.background-3{
    background: -moz-radial-gradient(circle at 90% 10%, rgba(255, 246, 41, 0.89) 0%, rgba(255, 246, 41, 0.11) 29%, rgba(255, 246, 41, 0.01) 37%),-moz-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: -webkit-radial-gradient(circle at 90% 10%, rgba(255, 246, 41, 0.89) 0%, rgba(255, 246, 41, 0.11) 29%, rgba(255, 246, 41, 0.01) 37%),-webkit-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: radial-gradient(circle at 90% 10%, rgba(255, 246, 41, 0.89) 0%, rgba(255, 246, 41, 0.11) 29%, rgba(255, 246, 41, 0.01) 37%),linear-gradient(90deg, #171717 0%, #333333 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171717",endColorstr="#333333",GradientType=1);
}
.background-3 .service-modal-button:hover {
    border: 1px solid rgba(255, 246, 41, 0.89);
    background-color: rgba(255, 246, 41, 0.11);
}

.background-4{
    background: -moz-radial-gradient(circle at 90% 10%, rgba(0, 255, 133, 0.89) 0%, rgba(0, 255, 133, 0.11) 29%, rgba(0, 255, 133, 0.01) 37%),-moz-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: -webkit-radial-gradient(circle at 90% 10%, rgba(0, 255, 133, 0.89) 0%, rgba(0, 255, 133, 0.11) 29%, rgba(0, 255, 133, 0.01) 37%),-webkit-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: radial-gradient(circle at 90% 10%, rgba(0, 255, 133, 0.89) 0%, rgba(0, 255, 133, 0.11) 29%, rgba(0, 255, 133, 0.01) 37%),linear-gradient(90deg, #171717 0%, #333333 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171717",endColorstr="#333333",GradientType=1);
}

.background-5{
    background: -moz-radial-gradient(circle at 90% 10%, rgba(0, 194, 255, 0.89) 0%, rgba(0, 194, 255, 0.11) 29%, rgba(0, 194, 255, 0.01) 37%),-moz-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: -webkit-radial-gradient(circle at 90% 10%, rgba(0, 194, 255, 0.89) 0%, rgba(0, 194, 255, 0.11) 29%, rgba(0, 194, 255, 0.01) 37%),-webkit-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: radial-gradient(circle at 90% 10%, rgba(0, 194, 255, 0.89) 0%, rgba(0, 194, 255, 0.11) 29%, rgba(0, 194, 255, 0.01) 37%),linear-gradient(90deg, #171717 0%, #333333 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171717",endColorstr="#333333",GradientType=1);
}

.background-6{
    background: -moz-radial-gradient(circle at 90% 10%, rgba(255, 246, 41, 0.89) 0%, rgba(255, 246, 41, 0.11) 29%, rgba(255, 246, 41, 0.01) 37%),-moz-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: -webkit-radial-gradient(circle at 90% 10%, rgba(255, 246, 41, 0.89) 0%, rgba(255, 246, 41, 0.11) 29%, rgba(255, 246, 41, 0.01) 37%),-webkit-linear-gradient(90deg, #171717 0%, #333333 100%);
    background: radial-gradient(circle at 90% 10%, rgba(255, 246, 41, 0.89) 0%, rgba(255, 246, 41, 0.11) 29%, rgba(255, 246, 41, 0.01) 37%),linear-gradient(90deg, #171717 0%, #333333 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#171717",endColorstr="#333333",GradientType=1);
}
.background-6 .service-modal-button:hover {
    border: 1px solid rgba(255, 246, 41, 0.89);
    background-color: rgba(255, 246, 41, 0.11);
}

.background-5 .service-modal-button:hover {
    border: 1px solid rgba(0, 194, 255, 0.89);
    background-color: rgba(0, 194, 255, 0.11);
}

.background-4 .service-modal-button:hover {
    border: 1px solid rgba(0, 255, 133, 0.89);
    background-color: rgba(0, 255, 133, 0.11);
} */

@media only screen and (max-width: 1200px) {
    .service-card-image{
    visibility: hidden;
}
}
@media only screen and (max-width: 800px) {
    .service-desc-serv-card{
        font-size:90% !important;
    }
    .service-card-front, .service-card-back {
        transform: none !important;
    }
    }

@media only screen and (max-width: 1400px) {
    .service-card-image{
        margin-left: 5% !important;
    }
}

@media only screen and (max-width: 1000px) {
    .service-card-image{
        visibility: hidden;
        width: 0px !important;
    }
    .service-title{
        font-size: x-large;
    }
    .service-card-text{
        width: 400% !important;
    }
    .service-modal-button{
        width: 120% !important;
    }
    .modal-container{
        width: 150% !important;
        padding-right: 8%;
    }
    .service-card{
        background-repeat: initial !important;
        /* background-blend-mode:overlay !important; */
    }
    .service-card .service-desc-serv-card{
        color: rgb(205, 204, 204);
    }
}

.modal-body-text{
    font-size: small !important;
}

.service-card-image{
    width: 100%;
    height: 150px;
    margin-top: 5%;
    margin-left: 10%;
    /* position:absolute; */
    /* margin-top: 0%;
    margin-left: 65%; */
}

.globe{
    margin-top: 30%;
    margin-left: 80%;
    /* margin-left: 69%;
    width: 80%; */
}

.modal-container{
    /* border: 1px solid red; */
    width: 20%;
    display: flex;
    flex-direction:column-reverse;
    margin-bottom: 2%;
}

.main-modal{
    text-align: left;
    font-size: medium;
    white-space: break-spaces;
}

.modal-header{
    font-size: x-small !important;
}

.service-modal-button{
    width: 70%;
    height: 10%;
    font-size: small;
    border: 1px solid black;
    background-color: black;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
    /* font-weight: 700; */
}
